import { toast } from "react-toastify";
import axiosClient from "../utils/axios.tsx";
import Cookies from "js-cookie";

class User {
  async loginUser(values) {
    try {
      const user = await axiosClient().post("users/log-in", values);

      return user.data;
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong!");

      console.log("err=>", error);

      return error;
    }
  }

  async getUserByToken() {
    const id = Cookies.get("employee-user-id");
    try {
      const user = await axiosClient().get(`users/${id}`);
      return user.data;
    } catch (error) {
      console.error("error", error);

      throw new Error(error);
    }
  }
}

export const usersApi = new User();

// import React, { useState } from "react";
// import type { TableColumnsType, TableProps } from "antd";
// import { Table, Typography, Tooltip } from "antd";

// type OnChange = NonNullable<TableProps<DataType>["onChange"]>;

// type GetSingle<T> = T extends (infer U)[] ? U : never;
// type Sorts = GetSingle<Parameters<OnChange>[2]>;

// interface DataType {
//   key: string;
//   company: string;
//   referred: string;
//   payout: string;
//   amount: string;
//   total: string;
//   status: any;
//   view: any;
// }

// const TableSection: React.FC<{
//   tableData: any;
//   setIsViewOpen: any;
//   setViewData: any;
// }> = ({
//   tableData,
//   setIsViewOpen,
//   setViewData,
// }: any) => {
//   const [sortedInfo, setSortedInfo] = useState<Sorts>({});
//   const { Text } = Typography;
//   const handleChange: OnChange = (pagination, filters, sorter) => {
//     setSortedInfo(sorter as Sorts);
//   };

//   const StatusBtn = ({ item }) => {
//     return (
//       <Text
//         style={
//           item === "Unpaid" || item === "Declined" || item === "Pending"
//             ? { backgroundColor: "#FFFAEB", color: "#B54708" }
//             : { backgroundColor: "#ECFDF3", color: "#027A48" }
//         }
//         className="text-[14px] font-medium w-auto rounded-[16px] p-1 px-3"
//       >
//         {item}
//       </Text>
//     );
//   };

//   const ButtonComponent = (item) => {
//     return (
//       <div className="flex items-center  justify-end ">
//         <button
//           onClick={() => {
//             setIsViewOpen(true);
//             setViewData(item.item);
//           }}
//           className=" font-semibold text-[#101828] hover:text-[#004EEB]"
//         >
//           View
//         </button>
//       </div>
//     );
//   };

//   //Dummy Data
//   const data: DataType[] = [
//     {
//       key: "1",
//       company: "GolfPro",
//       referred: "31/08/2023",
//       status: <StatusBtn item="Unpaid" />,
//       payout: "-",
//       amount: "$50",
//       total: "$545.21",
//       view: <ButtonComponent />,
//     },
//     {
//       key: "2",
//       company: "Capsule",
//       referred: "31/08/2023",
//       status: <StatusBtn item="Paid" />,
//       payout: "31/08/2023",
//       amount: "$500",
//       total: "$510.1",

//       view: <ButtonComponent />,
//     },
//     {
//       key: "3",
//       company: "Command + R",
//       referred: "31/08/2023",
//       status: <StatusBtn item="Unpaid" />,
//       payout: "-",
//       amount: "$20",
//       total: "$45.21",

//       view: <ButtonComponent />,
//     },
//     {
//       key: "4",
//       company: "Hourglass",
//       referred: "31/08/2023",
//       status: <StatusBtn item="Unpaid" />,
//       payout: "-",
//       amount: "$10",
//       total: "$5.21",

//       view: <ButtonComponent />,
//     },
//   ];
//   //Making another copy so we can pass the item itself to the ButtonCOmponent
//   const mapToTableFormat = (data: DataType[]) => {
//     return data?.map((item, index) => ({
//       key: item.key,
//       company: item.company,
//       referred: item.referred,
//       status: item.status,
//       payout: item.payout,
//       amount: item.amount,
//       total: item.total,
//       view: <ButtonComponent item={item} />
//     }))
//   }

//   const dummyData = mapToTableFormat(data);

//   const columns: TableColumnsType<DataType> = [
//     {
//       title: (
//         <span className="flex items-center gap-2">
//           Date
//           <img
//             src="https://ik.imagekit.io/8extk8sjo/Icon%20(32).svg?updatedAt=1710923979449"
//             alt=""
//           />
//         </span>
//       ),
//       dataIndex: "date",
//       key: "date",
//       className: "py-10 h-[40px] font-semibold text-[#101828]",
//     },
//     {
//       title: (
//         <Tooltip
//           placement="top"
//           title="Payments made may take up to 3-5 days to reflect on your bank account. "
//         >
//           <span className="flex items-center gap-2">Company</span>
//         </Tooltip>
//       ),
//       dataIndex: "company",
//       key: "company",
//     },
//     {
//       title: (
//         <span className="flex items-center gap-2">
//           Revenue Event
//           <img
//             src="https://ik.imagekit.io/8extk8sjo/Icon%20(32).svg?updatedAt=1710923979449"
//             alt=""
//           />
//         </span>
//       ),
//       dataIndex: "event",
//       key: "event",
//     },
//     {
//       title: (
//         <span className="flex items-center gap-2">
//           Revenue Amount
//           <img
//             src="https://ik.imagekit.io/8extk8sjo/Icon%20(32).svg?updatedAt=1710923979449"
//             alt=""
//           />
//         </span>
//       ),
//       dataIndex: "amount",
//       key: "amount",
//     },
//     {
//       title: (
//         <span className="flex items-center gap-2">
//           Commission Percent
//           <img
//             src="https://ik.imagekit.io/8extk8sjo/Icon%20(32).svg?updatedAt=1710923979449"
//             alt=""
//           />
//         </span>
//       ),
//       dataIndex: "percent",
//       key: "percent",
//     },
//     {
//       title: (
//         <span className="flex items-center gap-2">
//           Commission amount
//           <img
//             src="https://ik.imagekit.io/8extk8sjo/Icon%20(32).svg?updatedAt=1710923979449"
//             alt=""
//           />
//         </span>
//       ),
//       dataIndex: "c_amount",
//       key: "c_amount",
//     },
//     {
//       title: (
//         <Tooltip placement="top">
//           <span className="flex items-center gap-2">
//             Status
//             <img
//               src="https://ik.imagekit.io/8extk8sjo/Icon%20(36).svg?updatedAt=1711585625996"
//               alt=""
//             />
//             <img
//               src="https://ik.imagekit.io/8extk8sjo/Icon%20(32).svg?updatedAt=1710923979449"
//               alt=""
//             />
//           </span>
//         </Tooltip>
//       ),
//       dataIndex: "View",
//       key: "View",
//     },
//   ];
//   // [
//     // {
//     //   title: (
//     //     <span className="flex items-center gap-2">
//     //       Date
//     //       <img
//     //         src="https://ik.imagekit.io/8extk8sjo/Icon%20(32).svg?updatedAt=1710923979449"
//     //         alt=""
//     //       />
//     //     </span>
//     //   ),
//     //   dataIndex: "referred",
//     //   key: "referred",
//     //   sorter: (a, b) => a.referred.length - b.referred.length,
//     //   sortOrder: sortedInfo.columnKey === "referred" ? sortedInfo.order : null,
//     //   ellipsis: true,
//     //   sortDirections: [],
//     // },

//     // {
//     //   title: (
//     //     <span className="flex items-center gap-2">
//     //       Company
//     //       <img
//     //         src="https://ik.imagekit.io/8extk8sjo/Icon%20(32).svg?updatedAt=1710923979449"
//     //         alt=""
//     //       />
//     //     </span>
//     //   ),
//     //   dataIndex: "company",
//     //   key: "company",
//     //   sorter: (a, b) => a.company.length - b.company.length,
//     //   sortOrder: sortedInfo.columnKey === "company" ? sortedInfo.order : null,
//     //   ellipsis: true,
//     //   className: "w-[200px] py-10  h-[40px] font-semibold text-[#101828]",
//     //   sortDirections: [],
//     // },
//     // {
//     //   title: (
//     //     <span className="flex items-center gap-2">
//     //       Status
//     //       <img
//     //         src="https://ik.imagekit.io/8extk8sjo/Icon%20(36).svg?updatedAt=1711585625996"
//     //         alt=""
//     //       />
//     //       <img
//     //         src="https://ik.imagekit.io/8extk8sjo/Icon%20(32).svg?updatedAt=1710923979449"
//     //         alt=""
//     //       />
//     //     </span>
//     //   ),
//     //   dataIndex: "status",
//     //   key: "status",

//     //   sorter: (a, b) => a.status.length - b.status.length,
//     //   sortOrder: sortedInfo.columnKey === "status" ? sortedInfo.order : null,
//     //   ellipsis: true,
//     //   sortDirections: [],
//     // },

//     // {
//     //   title: (
//     //     <span className="flex items-center gap-2">
//     //       Payout Date
//     //       <img
//     //         src="https://ik.imagekit.io/8extk8sjo/Icon%20(32).svg?updatedAt=1710923979449"
//     //         alt=""
//     //       />
//     //     </span>
//     //   ),
//     //   dataIndex: "payout",
//     //   key: "payout",

//     //   sorter: (a, b) => a.payout.length - b.payout.length,
//     //   sortOrder: sortedInfo.columnKey === "payout" ? sortedInfo.order : null,
//     //   ellipsis: true,
//     //   sortDirections: [],
//     // },
//     // {
//     //   title: (
//     //     <span className="flex items-center gap-2">
//     //       Payout Amount
//     //       <img
//     //         src="https://ik.imagekit.io/8extk8sjo/Icon%20(32).svg?updatedAt=1710923979449"
//     //         alt=""
//     //       />
//     //     </span>
//     //   ),
//     //   dataIndex: "amount",
//     //   key: "amount",

//     //   sorter: (a, b) => a.amount.length - b.amount.length,
//     //   sortOrder: sortedInfo.columnKey === "amount" ? sortedInfo.order : null,
//     //   ellipsis: true,
//     //   className: "font-semibold text-[#101828]",
//     //   sortDirections: [],
//     // },
//     // {
//     //   title: (
//     //     <span className="flex items-center gap-2">
//     //       Total Earning
//     //       <img
//     //         src="https://ik.imagekit.io/8extk8sjo/Icon%20(32).svg?updatedAt=1710923979449"
//     //         alt=""
//     //       />
//     //     </span>
//     //   ),
//     //   dataIndex: "total",
//     //   key: "total",

//     //   sorter: (a, b) => a.total.length - b.total.length,
//     //   sortOrder: sortedInfo.columnKey === "total" ? sortedInfo.order : null,
//     //   ellipsis: true,
//     //   className: "font-semibold text-[#101828]",
//     //   sortDirections: [],
//     // },

//     // {
//     //   title: "",
//     //   dataIndex: "view",
//     //   key: "view",
//     //   ellipsis: true,
//     //   className: "w-[70px]",
//     // },
//   // ];

//   window.localStorage.setItem("payout_counts", columns?.length.toString());

//   return (
//     <>
//       <Table
//         columns={columns}
//         dataSource={dummyData}
//         onChange={handleChange}
//         pagination={false}
//       />
//     </>
//   );
// };

// export default TableSection;


import React, { useState } from "react";
import type { TableColumnsType, TableProps } from "antd";
import { Table, Typography, Tooltip } from "antd";

type OnChange = NonNullable<TableProps<DataType>["onChange"]>;

type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

interface DataType {
  key: string;
  company: string;
  referred: string;
  payout: string;
  amount: string;
  total: string;
  status: any;
  view: any;
}

const StatusBtn: React.FC<{ item: string }> = ({ item }) => {
  const { Text } = Typography;
  return (
    <Text
      style={
        item === "Unpaid" || item === "Declined" || item === "Pending"
          ? { backgroundColor: "#FFFAEB", color: "#B54708" }
          : { backgroundColor: "#ECFDF3", color: "#027A48" }
      }
      className="text-[14px] font-medium w-auto rounded-[16px] p-1 px-3"
    >
      {item}
    </Text>
  );
};

const ButtonComponent: React.FC<{ setIsViewOpen: (open: boolean) => void }> = ({
  setIsViewOpen,
}) => {
  return (
    <div className="flex items-center justify-end">
      <button
        onClick={() => setIsViewOpen(true)}
        className="font-semibold text-[#101828] hover:text-[#004EEB]"
      >
        View
      </button>
    </div>
  );
};

const TableSection: React.FC<{
  setIsViewOpen: any;
  isShow: boolean;
  payouts: any;
}> = ({ setIsViewOpen, isShow, payouts }) => {
  const [sortedInfo, setSortedInfo] = useState<Sorts>({});

  const handleChange: OnChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter as Sorts);
  };

  // console.log("Payouts in table: ",payouts);

  const columns: TableColumnsType<DataType> = [
    {
      title: (
        <span className="flex items-center gap-2">
          Date
          <img
            src="https://ik.imagekit.io/8extk8sjo/Icon%20(32).svg?updatedAt=1710923979449"
            alt=""
          />
        </span>
      ),
      dataIndex: "date",
      key: "date",
      className: "h-[40px] font-semibold text-[#101828]",
    },
    {
      title: (
        <Tooltip
          placement="top"
          title="Payments made may take up to 3-5 days to reflect on your bank account. "
        >
          <span className="flex items-center gap-2">Company</span>
        </Tooltip>
      ),
      dataIndex: "company",
      key: "company",
    },
    {
      title: (
        <span className="flex items-center gap-2">
          Revenue Event
          <img
            src="https://ik.imagekit.io/8extk8sjo/Icon%20(32).svg?updatedAt=1710923979449"
            alt=""
          />
        </span>
      ),
      dataIndex: "event",
      key: "event",
    },
    {
      title: (
        <span className="flex items-center gap-2">
          Revenue Amount
          <img
            src="https://ik.imagekit.io/8extk8sjo/Icon%20(32).svg?updatedAt=1710923979449"
            alt=""
          />
        </span>
      ),
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: (
        <span className="flex items-center gap-2">
          Commission Percent
          <img
            src="https://ik.imagekit.io/8extk8sjo/Icon%20(32).svg?updatedAt=1710923979449"
            alt=""
          />
        </span>
      ),
      dataIndex: "percent",
      key: "percent",
    },
    {
      title: (
        <span className="flex items-center gap-2">
          Commission amount
          <img
            src="https://ik.imagekit.io/8extk8sjo/Icon%20(32).svg?updatedAt=1710923979449"
            alt=""
          />
        </span>
      ),
      dataIndex: "c_amount",
      key: "c_amount",
    },
    {
      title: (
        <Tooltip placement="top">
          <span className="flex items-center gap-2">
            Status
            <img
              src="https://ik.imagekit.io/8extk8sjo/Icon%20(36).svg?updatedAt=1711585625996"
              alt=""
            />
            <img
              src="https://ik.imagekit.io/8extk8sjo/Icon%20(32).svg?updatedAt=1710923979449"
              alt=""
            />
          </span>
        </Tooltip>
      ),
      dataIndex: "View",
      key: "View",
    },
  ];

  return isShow ? (
    <Table
      columns={columns}
      dataSource={payouts}
      onChange={handleChange}
      pagination={false}
    />
  ) : null;
};

// Transform the original data to match DataType
const transformData = (data) => {
  return data?.map((item, index) => ({
    key: index.toString(),
    company: item.pipedrive_org_id.Name,
    referred: new Date(item.created_at).toLocaleDateString(),
    payout:
      item.payment_status === "Paid"
        ? new Date(item.updated_at).toLocaleDateString()
        : "-",
    amount: `$${parseFloat(item.commission).toFixed(2)}`,
    total: `$${parseFloat(item.total_revenue).toFixed(2)}`,
    status: <StatusBtn item={item.is_approved ? "Paid" : "Unpaid"} />,
    view: <ButtonComponent setIsViewOpen={() => console.log("View clicked")} />,
  }));
};

export default TableSection;

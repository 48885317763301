// @ts-nocheck
// import React, { useState } from "react";
// import { Logo, InputField } from "../../../components/index.ts";
// import { Typography, Button, Spin } from "antd";
// import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
// import { useNavigate, useLocation } from "react-router-dom";
// import axiosClient from "../../../utils/axios.tsx";
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import { toast } from "react-toastify";

// const validationSchema = Yup.object().shape({
//   name: Yup.string().required("Name is required"),
//   email: Yup.string().email("Invalid email").required("Email is required"),
//   password: Yup.string()
//     .min(8, "Password must be at least 8 characters")
//     .required("Password is required"),
// });

// const SignupForm = () => {
//   const { Text, Title } = Typography;
//   const [show,setShow] = useState(false);
//   const navigate = useNavigate();
//   const location = useLocation();
//   const searchParams = new URLSearchParams(location.search);
//   const email = searchParams.get("email");
//   const model_id = searchParams.get("model_id");

//   const CustomIcon = () => {
//     return (
//       <img
//         src="https://ik.imagekit.io/8extk8sjo/Social%20icon.svg?updatedAt=1709103355258"
//         alt=""
//         width={`25px`}
//       />
//     );
//   };

//   return (
//     <div className="w-full flex items-center justify-center pt-12 signupForm">
//       <div className="w-[50%] h-full">
//         <Logo />
//         <h3 className="signupText pt-20" style={{ fontWeight: "700" }}>
//           Hi there
//         </h3>
//         <span className="text-[16px] text-[#667085] fonts">
//           Enter your details below
//         </span>
//         <div className="pt-6">
//           <Formik
//             initialValues={{
//               name: "",
//               email: email || null,
//               password: "",
//               user_type_id: 3,
//               role: "EMPLOYEE",
//               user_summary: "",
//               commission_model_id: parseInt(model_id) || null,
//               phone: "",
//               agree: true,
//             }}
//             validationSchema={validationSchema}
//             onSubmit={(values, { setSubmitting }) => {
//               axiosClient()
//                 .post("/users/employee", values)
//                 .then((res) => {
//                   toast.success("Thanks for signing up");
//                   setSubmitting(false);
//                   navigate("/signin");
//                 })
//                 .catch((error) => {
//                   console.log("error", error);
//                   toast.error(error.response.data.message);
//                   setSubmitting(false);
//                 });
//             }}
//           >
//             {({ errors, touched, isSubmitting }) => (
//               <Form>
//                 <div className="pt-4">
//                   <div>
//                     <Title
//                       level={5}
//                       style={{
//                         color: "#344054",
//                       }}
//                       className="font-sans font-nomral text-[14px]"
//                     >
//                       Name*
//                     </Title>
//                     <Field
//                       placeholder="Enter your name"
//                       label="Name*"
//                       name="name"
//                       className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
//                     />
//                     <div className="h-[16px]">
//                       <ErrorMessage
//                         name="name"
//                         render={(msg) => (
//                           <div className="text-red-500  text-[12px] h-[5px] ">
//                             {msg}
//                           </div>
//                         )}
//                       />
//                     </div>
//                   </div>
//                   <div>
//                     <Title
//                       level={5}
//                       style={{
//                         color: "#344054",
//                       }}
//                       className="font-sans font-nomral text-[14px]"
//                     >
//                       Email*
//                     </Title>
//                     <Field
//                       placeholder="Enter your email"
//                       label="Email*"
//                       name="email"
//                       disabled
//                       className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
//                     />
//                     <div className="h-[16px]">
//                       <ErrorMessage
//                         name="email"
//                         render={(msg) => (
//                           <div className="text-red-500  text-[12px] h-[5px] ">
//                             {msg}
//                           </div>
//                         )}
//                       />
//                     </div>
//                   </div>
//                   <div>
//                     <Title
//                       level={5}
//                       style={{
//                         color: "#344054",
//                       }}
//                       className="font-sans font-nomral text-[14px] "
//                     >
//                       Password*
//                     </Title>
//                     <div className="relative">
//                     <Field
//                       type={show ? "text" : "password"}
//                       // type="password"
//                       name="password"
//                       placeholder="Enter your password"
//                       className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
//                     />
//                     <div onClick={() => setShow(!show)} className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer text-gray-400">
//                       {show ? <EyeInvisibleOutlined/> : <EyeOutlined/>}
//                     </div>
//                     </div>
//                     <div className="h-[10px]">
//                       <ErrorMessage
//                         name="password"
//                         render={(msg) => (
//                           <div className="text-red-500 text-[12px] h-[5px]">
//                             {msg}
//                           </div>
//                         )}
//                       />
//                     </div>
//                   </div>
//                   <Button
//                     type="primary"
//                     htmlType="submit"
//                     className="w-full font-sans h-[44px] bg-[#155EEF] rounded-[8px] font-semibold text-[16px] text-[#fff] mt-4"
//                     disabled={isSubmitting} // Disable button while submitting
//                   >
//                     {isSubmitting ? <Spin /> : "Get started"}
//                   </Button>
//                 </div>
//               </Form>
//             )}
//           </Formik>
//           <div className="text-center mt-6 pt-5">
//             <Text
//               style={{
//                 color: "#475467",
//                 fontFamily: "Inter",
//                 fontSize: "14px",
//               }}
//               className=" text-[14px]"
//             >
//               Already have an account? {""}
//               <span
//                 onClick={() => navigate("/")}
//                 className="text-[#004EEB] cursor-pointer font-semibold text-[14px]"
//               >
//                 Log in
//               </span>
//             </Text>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SignupForm;

// @ts-nocheck
import React, { useState } from "react";
import { Logo, InputField } from "../../../components/index.ts";
import { Typography, Button, Spin } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import axiosClient from "../../../utils/axios.tsx";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
});

const SignupForm = () => {
  const { Text, Title } = Typography;
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");
  const model_id = searchParams.get("model_id");

  const CustomIcon = () => {
    return (
      <img
        src="https://ik.imagekit.io/8extk8sjo/Social%20icon.svg?updatedAt=1709103355258"
        alt=""
        width={`25px`}
      />
    );
  };

  const checkInvite = async (email, role) => {
    try {
      const response = await axiosClient().get(
        `/invites/find?email=${email}&role=${role}`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  };

  const deleteInvite = async (id) => {
    try {
      await axiosClient().delete(`/invites/${id}`);
    } catch (error) {
      console.error("Error deleting invite:", error);
    }
  };

  return (
    <div className="w-full flex items-center justify-center pt-12 signupForm">
      <div className="w-[50%] h-full">
        <Logo />
        <h3 className="signupText pt-20" style={{ fontWeight: "700" }}>
          Hi there
        </h3>
        <span className="text-[16px] text-[#667085] fonts">
          Enter your details below
        </span>
        <div className="pt-6">
          <Formik
            initialValues={{
              name: "",
              email: email || null,
              password: "",
              user_type_id: 3,
              role: "EMPLOYEE",
              user_summary: "",
              commission_model_id: parseInt(model_id) || null,
              phone: "",
              agree: true,
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                const invite = await checkInvite(values.email, values.role);
                if (!invite) {
                  toast.error("You are not invited to sign up as Employee.");
                  setSubmitting(false);
                  return;
                }

                const response = await axiosClient().post(
                  "/users/employee",
                  values
                );
                await deleteInvite(invite.id);
                toast.success("Thanks for signing up");
                setSubmitting(false);
                navigate("/signin");
              } catch (error) {
                console.log("error", error);
                toast.error(
                  error.response?.data?.message ||
                    "An error occurred during sign up"
                );
                setSubmitting(false);
              }
            }}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <div className="pt-4">
                  <div>
                    <Title
                      level={5}
                      style={{
                        color: "#344054",
                      }}
                      className="font-sans font-nomral text-[14px]"
                    >
                      Name*
                    </Title>
                    <Field
                      placeholder="Enter your name"
                      label="Name*"
                      name="name"
                      className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
                    />
                    <div className="h-[16px]">
                      <ErrorMessage
                        name="name"
                        render={(msg) => (
                          <div className="text-red-500  text-[12px] h-[5px] ">
                            {msg}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div>
                    <Title
                      level={5}
                      style={{
                        color: "#344054",
                      }}
                      className="font-sans font-nomral text-[14px]"
                    >
                      Email*
                    </Title>
                    <Field
                      placeholder="Enter your email"
                      label="Email*"
                      name="email"
                      disabled
                      className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
                    />
                    <div className="h-[16px]">
                      <ErrorMessage
                        name="email"
                        render={(msg) => (
                          <div className="text-red-500  text-[12px] h-[5px] ">
                            {msg}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div>
                    <Title
                      level={5}
                      style={{
                        color: "#344054",
                      }}
                      className="font-sans font-nomral text-[14px] "
                    >
                      Password*
                    </Title>
                    <div className="relative">
                      <Field
                        type={show ? "text" : "password"}
                        // type="password"
                        name="password"
                        placeholder="Enter your password"
                        className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
                      />
                      <div
                        onClick={() => setShow(!show)}
                        className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer text-gray-400"
                      >
                        {show ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                      </div>
                    </div>
                    <div className="h-[10px]">
                      <ErrorMessage
                        name="password"
                        render={(msg) => (
                          <div className="text-red-500 text-[12px] h-[5px]">
                            {msg}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="w-full font-sans h-[44px] bg-[#155EEF] rounded-[8px] font-semibold text-[16px] text-[#fff] mt-4"
                    disabled={isSubmitting} // Disable button while submitting
                  >
                    {isSubmitting ? <Spin /> : "Get started"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          <div className="text-center mt-6 pt-5">
            <Text
              style={{
                color: "#475467",
                fontFamily: "Inter",
                fontSize: "14px",
              }}
              className=" text-[14px]"
            >
              Already have an account? {""}
              <span
                onClick={() => navigate("/")}
                className="text-[#004EEB] cursor-pointer font-semibold text-[14px]"
              >
                Log in
              </span>
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupForm;

export const NAV_ITEMS = [
  {
    label: "Dashboard",
    isCount: false,
    counts: 0,
    url: "/dashboard",
  },
  {
    label: "Comissions",
    isCount: true,
    counts: 10,
    url: "/payouts",
  },

  {
    label: "Settings",
    isCount: false,
    counts: 0,
    url: "/settings",
  },
  // {
  //   label: "Contact",
  //   isCount: false,
  //   counts: 0,
  //   url: "/contact",
  // },
];

export const TOTAL_CARDS = [
  {
    label: "Unpaid",
    counts: "$1200",
  },
  {
    label: "Paid",
    counts: "$4000",
  },
  {
    label: "Total earnings",
    counts: "$5200",
  },
];

export const TOTAL_CARDS2 = [
  {
    label: "Total companies referred",
    counts: "200K",
  },
  {
    label: "Total companies converted",
    counts: "20K",
  },
  {
    label: "Conversion Rate",
    counts: "4.5%",
  },
];

export const RECENT_ACTIVITY = [
  {
    icon: "https://ik.imagekit.io/8extk8sjo/Avatar.png?updatedAt=1709212217258",
    heading: "Demi Wikinson",
    name: "Adam Jones",
    date: "08/09/23",
  },
  {
    icon: "https://ik.imagekit.io/8extk8sjo/Avatar%20(3).svg?updatedAt=1709189197428",
    heading: "Aliah Lane",
    name: "Adam Jones",
    date: "08/09/23",
  },
  {
    icon: "https://ik.imagekit.io/8extk8sjo/Avatar%20(3).svg?updatedAt=1709189197428",
    heading: "Lana Steiner",
    name: "Adam Jones",
    date: "08/09/23",
  },
  {
    icon: "https://ik.imagekit.io/8extk8sjo/Avatar%20(3).svg?updatedAt=1709189197428",
    heading: "Demi Wikinson",
    name: "Adam Jones",
    date: "08/09/23",
  },
  {
    icon: "https://ik.imagekit.io/8extk8sjo/Avatar%20(3).svg?updatedAt=1709189197428",
    heading: "Demi Wikinson",
    name: "Adam Jones",
    date: "08/09/23",
  },
  {
    icon: "https://ik.imagekit.io/8extk8sjo/Avatar%20(3).svg?updatedAt=1709189197428",
    heading: "Demi Wikinson",
    name: "Adam Jones",
    date: "08/09/23",
  },
  {
    icon: "https://ik.imagekit.io/8extk8sjo/Avatar%20(3).svg?updatedAt=1709189197428",
    heading: "Demi Wikinson",
    name: "Adam Jones",
    date: "08/09/23",
  },
  {
    icon: "https://ik.imagekit.io/8extk8sjo/Avatar.png?updatedAt=1709212217258",
    heading: "Demi Wikinson",
    name: "Adam Jones",
    date: "08/09/23",
  },
  {
    icon: "https://ik.imagekit.io/8extk8sjo/Avatar%20(3).svg?updatedAt=1709189197428",
    heading: "Aliah Lane",
    name: "Adam Jones",
    date: "08/09/23",
  },
  {
    icon: "https://ik.imagekit.io/8extk8sjo/Avatar%20(3).svg?updatedAt=1709189197428",
    heading: "Lana Steiner",
    name: "Adam Jones",
    date: "08/09/23",
  },
];

export const ANCHOR = [
  {
    label: "Approvals",
  },
  {
    label: "Payments",
  },
];

export const FILTER_COMPANY = [
  {
    label: "Catalog",
  },
  {
    label: "Capsule",
  },
  {
    label: "Command+R",
  },
  {
    label: "Hourglass",
  },
  {
    label: "Layers",
  },
  {
    label: "Quotient",
  },
  {
    label: "Sisyphus",
  },
];

export const FILTER_NAME = [
  {
    label: "Brandon Workman",
  },
  {
    label: "Lindsey Vetrovs",
  },
  {
    label: "Mira Gouse",
  },
  {
    label: "Haylie George",
  },
];

export const FILTER_TYPE = [
  {
    label: "Affiliate",
  },
  {
    label: "Employee",
  },
];

export const FILTER_STATUS = [
  {
    label: "Unpaid",
    bgColor: "#FFFAEB",
    color: "#B54708",
  },
  {
    label: "Paid Automated",
    bgColor: "#ECFDF3",
    color: "#027A48",
  },
  {
    label: "Paid Manual",
    bgColor: "#ECFDF3",
    color: "#027A48",
  },
  {
    label: "Decline",
    bgColor: "#FEF3F2",
    color: "#B42318",
  },
];

export const TEAM_TABS = [
  {
    label: "Glovendor",
  },
  {
    label: "Affiliates",
  },
];

export const SETTINGS_TABS = [
  {
    label: "Profile",
  },
  // {
  //   label: "Get Paid",
  // },
  {
    label: "Password",
  },
];

export const SETTINGS_INTEGRATION = [
  {
    label: "Salesforce",
    icon: "https://ik.imagekit.io/8extk8sjo/salesforce-with-type-logo%201.svg?updatedAt=1709881615283",
    description: "Pull in leads, accounts and revenue data.",
    btntext: "Connect",
    isEnable: false,
  },
  {
    label: "Mercury",
    icon: "https://ik.imagekit.io/8extk8sjo/mercury-logo-icon%202.svg?updatedAt=1709881615323",
    description: "Automate your payments using your mercury account.",
    btntext: "View Integration",
    isEnable: true,
  },
];

export const PAYOUTS_DATA_COLUMN = [
  {
    title: "Name",
    dataIndex: "name",
    key: "column1",
  },
  {
    title: "Type",
    dataIndex: "Type",
    key: "column2",
  },
  {
    title: "Amount",
    dataIndex: "Amount",
    key: "column3",
  },
  {
    title: "Company",
    dataIndex: "Company",
    key: "column4",
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "column5",
  },

  {
    title: "Converted Date",
    dataIndex: "Date",
    key: "column6",
  },

  {
    title: "",
    dataIndex: "edit",
    key: "column7",
  },
];

export const PAYOUT_DATA = [
  {
    id: "1",
    name: "Brandon Workman",
    Type: "Affiliate",
    Amount: "$800",
    Company: "Mira gouse",
    Date: "24-08-10",
    Status: "Unpaid",
    edit: "",
  },
  {
    id: "2",
    name: "Ronaldo",
    Type: "Affiliate",
    Amount: "$12000",
    Company: "Mira gouse",
    Date: "24-05-11",
    Status: "Paid Automated",
    edit: "",
  },
  {
    id: "3",
    name: "Messi",
    Type: "Employee",
    Amount: "$2000",
    Company: "Mira gouse",
    Date: "24-07-20",
    Status: "Paid Manual",
    edit: "",
  },
  {
    id: "4",
    name: "Mape",
    Type: "Affiliate",
    Amount: "$200",
    Company: "Mira gouse",
    Date: "24-09-11",
    Status: "Declined",
    edit: "",
  },
];

export const COMPANIES_DATA_COLUMN = [
  {
    title: "Company",
    dataIndex: "name",
    key: "column1",
  },
  {
    title: "Referred By",
    dataIndex: "Type",
    key: "column2",
  },
  {
    title: "Closed By",
    dataIndex: "Amount",
    key: "column3",
  },
  {
    title: "Converted On",
    dataIndex: "Company",
    key: "column4",
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "column5",
  },

  {
    title: "Commission Paid",
    dataIndex: "Date",
    key: "column6",
  },
  // {
  //   title: "Total Revenue",
  //   dataIndex: "Date",
  //   key: "column6",
  // },

  {
    title: "",
    dataIndex: "edit",
    key: "column7",
  },
];

export const COMPANIES_DATA = [
  {
    id: "1",
    name: "Spotify",
    Type: "Kierra",
    Amount: "Kierra",
    Company: "Mira gouse",
    Date: "$6000",
    Status: "Unpaid",
    edit: "",
  },
  {
    id: "2",
    name: "Layers",
    Type: "Mira",
    Amount: "Mira",
    Company: "Mira gouse",
    Date: "$1000",
    Status: "Paid Automated",
    edit: "",
  },
  {
    id: "3",
    name: "Quotient",
    Type: "Omar",
    Amount: "Omar",
    Company: "Mira gouse",
    Date: "$6000",
    Status: "Paid Manual",
    edit: "",
  },
  {
    id: "4",
    name: "Sisyphus",
    Type: "Wilson",
    Amount: "Wilson",
    Company: "Mira gouse",
    Date: "$4000",
    Status: "Declined",
    edit: "",
  },
  {
    id: "4",
    name: "Sisyphus",
    Type: "Wilson",
    Amount: "Wilson",
    Company: "Mira gouse",
    Date: "$4000",
    Status: "Declined",
    edit: "",
  },
  {
    id: "4",
    name: "Sisyphus",
    Type: "Wilson",
    Amount: "Wilson",
    Company: "Mira gouse",
    Date: "$4000",
    Status: "Declined",
    edit: "",
  },
  {
    id: "4",
    name: "Sisyphus",
    Type: "Wilson",
    Amount: "Wilson",
    Company: "Mira gouse",
    Date: "$4000",
    Status: "Declined",
    edit: "",
  },
];

export const TEAMS_ADMIN_DATA = [
  {
    id: "1",
    icon: "",
    name: "Olivia Rhye",
    email: "email@company.com",
    role: "Admin",
  },
  {
    id: "2",

    icon: "",
    name: "Messi",
    email: "messi@company.com",
    role: "Owner",
  },
  {
    id: "3",

    icon: "",
    name: "Rhye",
    email: "yoho@company.com",
    role: "Admin",
  },
  {
    id: "4",

    icon: "",

    name: "Oliv",
    email: "oliv@company.com",
    role: "Admin",
  },
];

export const TEAMS_EMPLOYEE_DATA = [
  {
    id: "1",
    icon: "",
    name: "Drink",
    email: "email@company.com",
    Commission: "Flat 20% for Employees ",
  },
  {
    id: "2",

    icon: "",
    name: "Messi",
    email: "messi@company.com",
    Commission: "Flat 20% for Employees ",
  },
  {
    id: "3",

    icon: "",
    name: "Rhye",
    email: "yoho@company.com",
    Commission: "Flat 20% for Employees ",
  },
  {
    id: "4",

    icon: "",

    name: "Oliv",
    email: "oliv@company.com",
    Commission: "Flat 20% for Employees ",
  },
];

export const SETTING_METHOD = [
  {
    heading: "Wire Transfer",
    list: [
      "Charges applied for currency exchange.",
      "Free transfers for international USD payments.",
      "Up to 5 business days to receive funds.",
    ],
  },

  {
    heading: "Direct to US Bank (USD)",
    list: ["Free deposits to US banks", "Deposit to a US bank account in USD"],
  },
];

export const DASHBOARD_BTNS = [
  {
    label: "All time",
    className:
      "h-[44px] rounded-l-[6px] font-sans rounded-r-none  shadow-none p-[8px, 12px, 8px, 12px]  font-semibold text-[16px] text-[#1D2939] flex items-center gap-2",
    style: {
      shadow: "0px 1px 2px 0px #1018280D",
      border: "1px solid #D0D5DD",
    },
  },
  {
    label: "30 days",
    className:
      "h-[44px] rounded-none font-sans shadow-none  p-[8px, 12px, 8px, 12px]  font-semibold text-[16px] text-[#1D2939] flex items-center gap-2",
    style: {
      shadow: "0px 1px 2px 0px #1018280D",
      borderBottom: "1px solid #D0D5DD",
      borderTop: "1px solid #D0D5DD",
    },
  },
  {
    label: "7 days",
    className:
      "h-[44px] rounded-none font-sans shadow-none  p-[8px, 12px, 8px, 12px]  font-semibold text-[16px] text-[#1D2939] flex items-center gap-2",
    style: {
      shadow: "0px 1px 2px 0px #1018280D",
      borderBottom: "1px solid #D0D5DD",
      borderTop: "1px solid #D0D5DD",
    },
  },
  {
    label: "24 hours",
    className:
      "h-[44px] rounded-r-[6px] font-sans rounded-l-none shadow-none p-[8px, 12px, 8px, 12px]  font-semibold text-[16px] text-[#1D2939] flex items-center gap-2",
    style: {
      shadow: "0px 1px 2px 0px #1018280D",
      border: "1px solid #D0D5DD",
    },
  },
];

import React from 'react';
import ReactApexChart from 'react-apexcharts';

const LineChart: React.FC<{stats: any}> = ({stats}) => {
  
  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'area',
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: "monotoneCubic",
      width: 2
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    yaxis: {
      labels: {
        show: false,
      },
      tickAmount: 11,
    },
    colors: ['#155eef'],
    legend: {
      position: 'top'
    },
    fill: {
      gradient: {
        shadeIntensity: 0.1,
        type: "vertical",
        shade: "light",
        opacityFrom: 0.3,
        opacityTo: 0.05,
        stops: [0, 90, 100],
        colorStops: [
          {
            offset: 0,
            color: "#155eef",
            opacity: 0.3,
          },
          {
            offset: 5,
            color: "#155eef",
            opacity: 0.05,
          },
        ],
      },
    },
  };

  const series = [
    {
      name: 'Total $',
      data: stats?.result?.map(item => item?.total) || []
    }
  ];

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="area" height={350} />
    </div>
  );
};

export default LineChart;
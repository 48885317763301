import { useState } from "react";
import { statsApi } from "../services/stats.ts";
import { useDispatch } from "react-redux";
import { addOpenStats } from "../redux/stats.ts";
import { setReduxLoading } from "../redux/loading.ts";

const useOpenCommission = () => {
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const getStats = async (values: any, user: any) => {
    dispatch(setReduxLoading(true));
    try {
      // dispatch(startLoading());
      const stats = await statsApi.getAllStats(values, user);
      dispatch(addOpenStats(stats));

      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
    dispatch(setReduxLoading(false));
  };

  return {
    isLoading,
    getStats,
  };
};

export default useOpenCommission;

import axiosClient from "../utils/axios.tsx";

class Stats {
  async getAllStats(values, user) {
    const today = new Date();
    const currentDate = new Date(today);
    currentDate.setDate(currentDate.getDate() + 1);
    const todayISOString = currentDate.toISOString().split("T")[0];

    const legacyDate = new Date("01/01/2022");
    const legacyDateISOString = legacyDate.toISOString().split("T")[0];

    const twoYearsAgo = new Date();
    twoYearsAgo.setFullYear(today.getFullYear() - 2);
    const twoYearsAgoISOString = twoYearsAgo.toISOString().split("T")[0];

    // Get the start of the current year
    const startOfYear = new Date(today.getFullYear(), 0, 1);
    const startOfYearISOString = startOfYear.toISOString().split("T")[0];
    try {
      // const stats = await axiosClient().get(
      //   `pipedrive-organisations/stats/user?user_id=${user?.user_id}`
      // );

      if (user) {
        const stats = await axiosClient().get(
          `pipedrive-organisations/stats/user?user_id=${
            user?.user_id
          }&StartDate=${
            // values?.from ? values?.from : startOfYearISOString
            values?.from ? values?.from : legacyDateISOString
          }&EndDate=${values?.to ? values?.to : todayISOString}`
        );

        return stats.data;
      }
    } catch (error) {
      console.log("error =>", error);
    }
  }
}

export const statsApi = new Stats();
